/* eslint-disable max-len */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WhatsappButton } from '../../components/WhatsappButton';
import theme from '../../shared/theme';
import { messages } from '../../shared/constants';

export const About = () => {
  const whatsappMessage = messages.whatsappMessages.aboutPage.baseText.replace(messages.whatsappMessages.aboutPage.params.link, window.location.href);
  return (
    <Grid container mt={10} padding={4} direction={'column'} alignContent={'center'}>
      <Grid container direction={'column'}>
        <Typography variant='h4' color={theme.palette.primary.dark}> Contato </Typography>
        <WhatsappButton message={whatsappMessage} text='Fale conosco' />
      </Grid>
      <Grid container direction={'column'} rowGap={2}>
        <Typography variant='h4' color={theme.palette.primary.dark}>Localização</Typography>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7903.884259396317!2d-34.90157211157551!3d-7.901115080775358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab15b3db0aac43%3A0xec8b9822216d7f49!2sAv.%20Duque%20de%20Caxias%2C%201700%2C%20Abreu%20e%20Lima%20-%20PE%2C%2053565-570!5e0!3m2!1sen!2sbr!4v1670339787502!5m2!1sen!2sbr"
          width="400"
          height="300"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </Grid>

    </Grid>
  );
};

