import React, { useState } from 'react';
import TopBar from './components/TopBar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid } from '@mui/material';

import { ThemeProvider } from '@mui/material';
import theme from './shared/theme';
import MainPage from './pages/MainPage';
import VehiclePage from './pages/VehiclePage';
import { VehicleCreationPage } from './pages/VehicleCreationPage';

import {
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import { GlobalContext } from './shared/context';
import { constants } from './shared/constants';
import VehicleModel from './models/VehicleModels';
import { About } from './pages/About';
import { FilterSettings } from './components/Filter';

/**
 * App entrypoint
 * @return {JSX.Element}
 */
export default function App() {
  const [objectListings, setObjectListings] = useState<VehicleModel[]>([]);
  const [filteredObjectListings, setFilteredObjectListings] = useState<VehicleModel[]>([]);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<string>(constants.BLANK);
  const [filterSettings, setFilterSettings] = useState<FilterSettings>({} as FilterSettings);

  const globalContextVariables = {
    objectListings,
    setObjectListings,
    filteredObjectListings,
    setFilteredObjectListings,
    lastUpdateTimestamp,
    setLastUpdateTimestamp,
    filterSettings,
    setFilterSettings,
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <BrowserRouter>
        <GlobalContext.Provider value={globalContextVariables}>
          <Grid container >
            <TopBar isUserAdmin={false} />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/about" element={<About />} />
              <Route
                path="vehicle/:vehicleid"
                element={<VehiclePage />}
              />
              <Route path="vehicle/create"
                element={<VehicleCreationPage mode={'create'} />} />
              <Route path="vehicle/edit/:vehicleid"
                element={<VehicleCreationPage mode={'edit'} />} />
            </Routes>
          </Grid >
        </GlobalContext.Provider >
      </BrowserRouter>
    </ThemeProvider >
  );
}
