
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Divider, Grid, IconButton, SxProps, TextField, Theme, Typography } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { CSSProperties } from '@emotion/serialize';
import RangeSlider from './RangeSlider';
import CheckBoxGroup from './CheckBoxGroup';

import { constants, PRICE_INTERVAL, YEAR_INTERVAL } from '../../shared/constants';
import formatCurrency from '../../shared/utils';
import VehicleModel from '../../models/VehicleModels';
import { GlobalContext } from '../../shared/context';

const filterBoxStyle: CSSProperties = {
  borderRadius: '8px',
  border: 'solid gray 2px',
  marginBottom: 2,
  position: 'sticky',
  top: 75,
  bottom: 60,
  overflow: 'auto',
};

const filterGroupStyle: SxProps<Theme> = {
  marginBottom: 1,
  pt: 1,
  pl: 3,
  pr: 3,
};

export interface FilterSettings {
  searchValue: string;
  yearInterval: number[];
  priceInterval: number[];
  categories: string[];
}

const categoryValues = {
  vehicleType: ['Ônibus', 'Caminhão', 'Carro', 'Moto'],
};

/**
 * Grid filter
 * @param {Props} props filter props
 * @return {JSX.Element}
 */
export default function Filter() {
  const [searchValue, setSearchValue] = useState<string>(constants.BLANK);
  const [yearInterval, setYearInterval] = useState<number[]>(YEAR_INTERVAL);
  const [priceInterval, setPriceInterval] = useState<number[]>(PRICE_INTERVAL);
  const [categories, setCategories] = useState<string[]>(categoryValues.vehicleType);

  const {
    objectListings,
    filterSettings,
    setFilterSettings,
    setFilteredObjectListings,
  } = React.useContext(GlobalContext);

  useEffect(() => {
    if (Object.keys(filterSettings).length > 0) {
      handleLoadSettings();
    } else {
      handleResetFilter();
    }
  }, []);


  useEffect(() => {
    const newSettings: FilterSettings = {
      searchValue,
      yearInterval,
      priceInterval,
      categories,
    };
    setFilterSettings(newSettings);
  }, [searchValue, priceInterval, yearInterval, categories]);

  useEffect(() => {
    if (filterSettings) {
      handleFiltering();
    }
  }, [filterSettings]);

  const sanitizeString = (text: string): string => {
    const sanitizedText = text.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return sanitizedText;
  };

  const includesSanitized = (target: string, value: string) => {
    return sanitizeString(target).includes(sanitizeString(value));
  };

  const handleFiltering = () => {
    const filtered: VehicleModel[] = objectListings.filter((vehicle: VehicleModel) => {
      if (!(includesSanitized(vehicle.listingTitle, filterSettings.searchValue) ||
        includesSanitized(vehicle.model, filterSettings.searchValue) ||
        vehicle.brand && includesSanitized(vehicle.brand, filterSettings.searchValue) ||
        vehicle.description && includesSanitized(vehicle.description, filterSettings.searchValue)
      )) {
        return false;
      }
      if (vehicle.year < filterSettings.yearInterval[0] || vehicle.year > filterSettings.yearInterval[1]) {
        return false;
      }
      if (vehicle.price < filterSettings.priceInterval[0] || vehicle.price > filterSettings.priceInterval[1]) {
        return false;
      }
      if (!filterSettings.categories.includes(vehicle.category)) {
        return false;
      }
      return true;
    });
    setFilteredObjectListings(filtered);
  };

  const handleResetFilter = () => {
    setSearchValue(constants.BLANK);
    setPriceInterval(PRICE_INTERVAL);
    setYearInterval(YEAR_INTERVAL);
    setCategories(categoryValues.vehicleType);
    setFilteredObjectListings(objectListings);
  };

  const handleLoadSettings = () => {
    setSearchValue(filterSettings.searchValue);
    setYearInterval(filterSettings.yearInterval);
    setPriceInterval(filterSettings.priceInterval);
    setCategories(filterSettings.categories);
    setFilteredObjectListings(objectListings);
  };

  return (
    <Box position={'sticky'} sx={filterBoxStyle}>
      <Grid container>
        <Grid item p={2}>
          <TextField
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value)}
            label={'Marca/Modelo'}
            size={'small'}
            placeholder={'Mercedez, Volkswagen, etc'}
            fullWidth />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider />
        </Grid>
        <Grid container
          direction={'column'}
          sx={filterGroupStyle}
        >
          <Typography>{'Categoria'}</Typography>
          <CheckBoxGroup
            defaultValues={categoryValues.vehicleType}
            values={categories}
            setValues={setCategories}
          />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider />
        </Grid>
        <Grid container
          direction={'column'}
          sx={filterGroupStyle}
        >
          <Typography>{'Ano'}</Typography>
          <RangeSlider
            defaultValue={YEAR_INTERVAL}
            value={yearInterval}
            onUpdateValue={(range: number[]) => setYearInterval(range)}
          />
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <Divider />
        </Grid>
        <Grid container
          direction={'column'}
          sx={filterGroupStyle}
        >
          <Typography>{'Preço'}</Typography>
          <RangeSlider
            defaultValue={PRICE_INTERVAL}
            value={priceInterval}
            onUpdateValue={(range: number[]) => setPriceInterval(range)}
            step={2500}
            valueFormatter={(value) => formatCurrency(value)}
          />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <IconButton onClick={handleResetFilter}>
          <RestartAltIcon color={'primary'} />
        </IconButton>
      </Grid>
    </Box>
  );
};
