import { fetchAxios, useAxiosProps } from './axiosApi';

import { ADMIN_PASSWORD_KEY, constants, IS_ADMIN_PASSWORD_VALID, routes } from '../shared/constants';

const getCheckPasswordEndpoint = (password: string) => {
  return routes.AUTHENTICATION.GET.PATH.replace(routes.AUTHENTICATION.GET.PARAMS.PASSWORD, password);
};

export const getPassword = (): string => {
  const password = localStorage.getItem(ADMIN_PASSWORD_KEY);
  return password ? password : constants.BLANK;
};

export const setPassword = (password: string) => {
  localStorage.setItem(ADMIN_PASSWORD_KEY, password);
};

export const isAdmin = () => {
  const passwordStatus = localStorage.getItem(IS_ADMIN_PASSWORD_VALID);
  return passwordStatus === '1';
};

export const checkPassword = async () => {
  const password = localStorage.getItem(ADMIN_PASSWORD_KEY);
  const fetchProps: useAxiosProps = {
    endpoint: getCheckPasswordEndpoint(password ? password : constants.BLANK),
    defaultResponse: '0',
    config: { method: 'GET' },
  };

  await fetchAxios(fetchProps).then((response) => {
    if (response.data) {
      localStorage.setItem(IS_ADMIN_PASSWORD_VALID, response.data as string);
      localStorage.setItem(IS_ADMIN_PASSWORD_VALID, response.data.data);
      return response.data.data;
    } else {
      localStorage.setItem(IS_ADMIN_PASSWORD_VALID, '0');
      return false;
    }
  }).catch((error) => {
    console.log(error);
    return false;
  });
};
