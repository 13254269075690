import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

interface Props {
  defaultValues: string[];
  values: string[];
  setValues: ((values: string[]) => void);
}

/**
 * CheckBox Group
 * @param {Props} props filter props
 * @return {JSX.Element}
 */
export default function CheckBoxGroup(props: Props) {
  const handleChange = (value: string) => {
    let adjustedValues = [...props.values];
    if (props.values.includes(value)) {
      adjustedValues = props.values.filter((v) => v !== value);
    } else {
      adjustedValues.push(value);
    }
    props.setValues(adjustedValues);
  };

  return (
    <Box>
      {
        props.defaultValues.map((value) => {
          return (
            <FormControlLabel
              key={value}
              label={value}
              control={
                <Checkbox
                  key={value}
                  checked={props.values.includes(value)}
                  onChange={() => handleChange(value)}
                />}
            />
          );
        })
      }
    </Box>
  );
}
