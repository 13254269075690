import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Grid, IconButton, Typography } from '@mui/material';
import theme from '../../shared/theme';

interface Props {
  message: string;
  number?: string;
  text?: string;
}

export const WhatsappButton = (props: Props) => {
  const whatsappGreen = '#25D366';
  const phoneToUse = props.number ? props.number : process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;

  const whatsappURL = `https://wa.me/${phoneToUse}?text=${props.message}`;
  return (
    <a
      href={whatsappURL}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Grid container direction={'row'} alignItems={'center'}>
        {props.text ?
          <Typography variant='h3' sx={{ color: theme.palette.primary.main }}>{props.text}</Typography> : null}
        <IconButton>
          <WhatsAppIcon sx={{ color: whatsappGreen, width: 60, height: 60 }} />
        </IconButton>
      </Grid>
    </a>
  );
};
