import { createTheme } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';

const pallete = ['00487c', '4bb3fd', '3e6680', '0496ff', '027bce']; // TODO: Consider using this pallete

export default createTheme({
  palette: {
    background: {
      paper: grey[50],
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    primary: {
      main: blue[600],
    },
  },
});
