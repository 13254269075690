import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { messages } from '../../shared/constants';

import Filter from '../../components/Filter';
import VehicleModel from '../../models/VehicleModels';
import VehicleCard from '../../components/VehicleCard';

import { getAllVehiclesProps } from '../../hooks/vehicleListing';
import { useAxios } from '../../hooks/axiosApi';
import { GlobalContext } from '../../shared/context';
import toast from '../../shared/toast';

/**
 * Main Application Page
 * @return {JSX.Element}
 */
export default function MainPage() {
  const updateIntervalInSeconds = 3600;
  const loadLimitTimeInSeconds = 15;

  const [isLoadingTimeout, setIsLoadingTimeout] = useState(false);

  const {
    objectListings,
    setObjectListings,
    filteredObjectListings,
    setFilteredObjectListings,
    lastUpdateTimestamp,
    setLastUpdateTimestamp,
  } = React.useContext(GlobalContext);

  const preventUpdate = useMemo(() => {
    const shouldUpdate = lastUpdateTimestamp.length == 0 ||
      ((Date.now() - new Date(lastUpdateTimestamp).getTime()) > updateIntervalInSeconds * 1000);
    return !shouldUpdate;
  }, [lastUpdateTimestamp]);

  const { data: objects, error: errorGetVehicleListings, loaded } = useAxios(getAllVehiclesProps(), preventUpdate);

  const handleUpdateVehicleListing = () => {
    setObjectListings(objects);
    setFilteredObjectListings(objects);
    setLastUpdateTimestamp(Date.now().toString());
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingTimeout(true);
    }, loadLimitTimeInSeconds * 1000);
  }, []);

  useEffect(() => {
    if (errorGetVehicleListings) {
      toast.error(messages.toasts.error.getAll);
      console.log({ errorGetVehicleListings });
    }
  }, [errorGetVehicleListings]);

  const PlaceholderToUse = () => {
    return (!isLoadingTimeout ?
      <Skeleton
        animation='wave'
        variant='rectangular'
        width={300}
        height={400}
        sx={{ m: 3 }} /> :
      <EmptyListing
        totalCount={objectListings ? objectListings.length : 0} />
    );
  };

  useEffect(() => {
    if (objectListings.length == 0 && objects.length > 0 && !preventUpdate) {
      handleUpdateVehicleListing();
    }
  }, [objects, preventUpdate]);

  return (
    <Grid container justifyContent={'space-between'}
      sx={{ width: '100vw' }}
    >
      <Grid item xs={4.5} md={2}
        sx={{ marginTop: 1 }}
      >
        <Filter />
      </Grid>
      <Grid item xs={7.5} md={10}>
        {filteredObjectListings.length ?
          <VehicleList vehicles={filteredObjectListings} /> : <PlaceholderToUse />
        }
      </Grid>
    </Grid>
  );
}

interface VehicleListProps {
  vehicles: VehicleModel[];
}

/**
 * VehicleList
 * @param {VehicleListProps} props
 * @return {JSX.Element}
 */
function VehicleList(props: VehicleListProps) {
  return (
    <Grid container p={3} gap={3}>
      {props.vehicles.map((vehicle, index) => {
        return <VehicleCard key={`vehicleItem${index}`} vehicle={vehicle} />;
      })}
    </Grid>
  );
}

interface EmptyListingProps {
  totalCount: number;
}
const EmptyListing = (props: EmptyListingProps) => {
  return (
    <Grid item xs={6} alignSelf='center'>
      <Paper elevation={2}
        sx={{
          'padding': 2,
          'margin': 2,
          '&:hover': {
            boxShadow: '0 5px 15px rgba(30,136,229,0.3)',
          },
        }}>
        <Typography variant='h5'>
          {
            props.totalCount === 0 ?
              <a href='/' style={{ textDecoration: 'none', color: 'inherit' }}>{messages.warnings.mainPage.noListings} </a> :
              messages.warnings.mainPage.noFilteredListings
          }
        </Typography>
      </Paper>
    </Grid >
  );
};
