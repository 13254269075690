import { toast } from 'react-toastify';


const success = (message: string) => {
  toast.success(message);
};

const error = (message: string) => {
  toast.error(message);
};

export default { success, error };
