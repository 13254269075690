import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Grid, Typography } from '@mui/material';


interface Props {
  defaultValue: number[],
  value: number[],
  onUpdateValue: (value: number[]) => void;
  step?: number,
  valueFormatter?: (value: number) => string;
}

/**
 * Grid filter
 * @param {Props} props Component props
 * @return {JSX.Element}
 */
export default function defaultValueSlider(props: Props) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    props.onUpdateValue(newValue as number[]);
  };

  return (
    <Box >
      <Grid container justifyContent={'space-between'}>
        <Typography variant={'caption'}>{props.valueFormatter ? props.valueFormatter(props.value[0]) : props.value[0]}</Typography>
        <Typography variant={'caption'}>{props.valueFormatter ? props.valueFormatter(props.value[1]) : props.value[1]}</Typography>
      </Grid>

      <Slider
        min={props.defaultValue[0]}
        max={props.defaultValue[1]}
        step={props.step || 1}
        value={props.value}
        onChange={handleChange}
        valueLabelDisplay="off"
      />
    </Box>
  );
}
