import { Button, Grid, IconButton, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import LoginIcon from '@mui/icons-material/Login';

import { setPassword, checkPassword, isAdmin } from '../../../hooks/authentication';

import toast from '../../../shared/toast';
import { constants, messages } from '../../../shared/constants';
import { useNavigate } from 'react-router-dom';

interface Props {
  close: () => void;
}
const AdminLogin = (props: Props) => {
  const navigate = useNavigate();
  const isRootUser = isAdmin();
  const [currentPass, setCurrentPass] = useState(constants.BLANK);

  const handleLoginAttempt = async () => {
    setPassword(currentPass);
    await checkPassword();
    if (isAdmin()) {
      toast.success(messages.toasts.success.tryPassword);
      props.close();
      navigate('/');
    } else {
      toast.error(messages.toasts.error.tryPassword);
    }
  };

  const handleLogout = async () => {
    setCurrentPass(constants.BLANK);
    setPassword('*');
    await checkPassword();
    toast.success(messages.toasts.success.logout);
    props.close();
    navigate('/');
  };

  const hasInvalidChar = useMemo(() => {
    const invalidCharsRegex = /[^a-zA-Z0-9]/;
    const res = currentPass.match(invalidCharsRegex);
    return currentPass.length > 0 && (res !== null);
  }, [currentPass]);

  return (
    <Grid container alignContent='center'
      sx={{
        bgcolor: 'background.paper', p: 1, mt: 0.5, borderRadius: 2, border: 'solid 1px black',
      }}>
      {isRootUser ?
        <Button variant='outlined' onClick={handleLogout}>
          Sair
        </Button > :
        <Grid container
          alignContent='center'>
          <Grid item>
            <TextField
              value={currentPass}
              type={'password'}
              label={'Admin Login'}
              size={'small'}
              error={hasInvalidChar}
              sx={{ maxWidth: '150px' }}
              onChange={(event) => setCurrentPass(event.target.value)}
            />
          </Grid>
          <Grid item>
            <IconButton disabled={hasInvalidChar} size={'small'} onClick={handleLoginAttempt}>
              <LoginIcon />
            </IconButton>
          </Grid>
        </Grid>
      }

    </Grid >
  );
};

export default AdminLogin;
