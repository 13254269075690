import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<React.StrictMode>
  <Helmet>
    <meta charSet="utf-8" />
    <title lang="pt">{process.env.REACT_APP_STORE_NAME_LONG}</title>
    <meta name="description" content="Site de Veiculos" />
    <link rel="canonical" href="https://parkbus.com.br" />
    <link rel="icon" href="/assets/favicons/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
    <meta name="image" content="/assets/og.png" />
    <meta property="og:title" content="Spotify Profile" />
    <meta property="og:description" content="Web app for visualizing personalized Spotify data" />
    <meta property="og:image" content="/assets/og.png" />
  </Helmet>
  <App />
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
