import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BuildIcon from '@mui/icons-material/Build';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { Link } from 'react-router-dom';
import { Popper, SxProps } from '@mui/material';
import AdminLogin from './AdminLogin';
import { isAdmin } from '../../hooks/authentication';
import { CSSProperties } from '@mui/styled-engine';
import { constants } from '../../shared/constants';

const userPages = [{ label: 'Veículos', route: '/' }, { label: 'Sobre', route: '/about' }];
const adminPages = [{ label: 'Adicionar Veículo', route: '/vehicle/create' }];
const businessName = process.env.REACT_APP_STORE_NAME_LONG;
const businessNameShort = process.env.REACT_APP_STORE_NAME_SHORT;

interface Props {
  isUserAdmin: boolean;
}

const TopBar = (props: Props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorAdminLoginEl, setAnchorAdmingEl] = React.useState<null | HTMLElement>(null);
  const isRootUser = isAdmin();
  const pages = isRootUser ? [...userPages, ...adminPages] : userPages;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div style={{ marginBottom: '60px' }}>
      <AppBar position='fixed' >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <DirectionsBusIcon sx={{ display: 'flex', mr: 1 }} />
            <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }}>
              <Typography textAlign='center' variant='button' sx={{ display: 'flex', mr: 1 }}>{businessName}</Typography>
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <MenuItem key={page.route} onClick={handleCloseNavMenu}>
                    <Link to={page.route} style={{ textDecoration: 'none', color: '#1976d2' }}>
                      <Typography textAlign='center' variant='button'>{page.label}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ ml: 10, flexGrow: 2, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Link key={page.route} to={page.route} onClick={handleCloseNavMenu}
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    fontSize: '1.4rem',
                    fontFamily: 'monospace',
                    fontWeight: 900,
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}>
                  {page.label}
                </Link>
              ))}
            </Box>
            <IconButton
              sx={{ color: 'white' }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (anchorAdminLoginEl) {
                  setAnchorAdmingEl(null);
                } else {
                  setAnchorAdmingEl(event.currentTarget);
                }
              }} >
              {
                isRootUser ?
                  <SupervisorAccountIcon /> :
                  <BuildIcon />
              }
            </IconButton>
            <Popper disablePortal open={!!anchorAdminLoginEl} anchorEl={anchorAdminLoginEl} placement={'left-end'}>
              <AdminLogin close={() => setAnchorAdmingEl(null)} />
            </Popper>
          </Toolbar>
        </Container>
      </AppBar>
    </div >
  );
};
export default TopBar;
