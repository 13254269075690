export const PRICE_INTERVAL = [10000, 200000];
export const YEAR_INTERVAL = [1970, 2022];
export const ADMIN_PASSWORD_KEY = 'ADMIN_PASSWORD';
export const IS_ADMIN_PASSWORD_VALID = 'IS_PASSWORD_VALID';

export const routes = {
  VEHICLE_LISTING: {
    GET: {
      ALL: {
        PATH: '/api/vehicleListing/getAll',
      },
      BY_ID: {
        PATH: '/api/vehicleListing/getOne/:listingId',
        PARAMS: {
          ID: ':listingId',
        },
      },
    },
    POST: {
      ONE: {
        PATH: '/api/vehicleListing/postOne',
      },
      QUERY_PARAMS: {
        PASSWORD: 'password',
      },
    },
    PATCH: {
      BY_ID: {
        PATH: '/api/vehicleListing/update/:listingId',
        PARAMS: {
          ID: ':listingId',
        },
        QUERY_PARAMS: {
          PASSWORD: 'password',
        },
      },

    },
    DELETE: {
      BY_ID: {
        PATH: '/api/vehicleListing/delete/:id',
        PARAMS: {
          ID: ':id',
        },
        QUERY_PARAMS: {
          PASSWORD: 'password',
        },
      },
    },
  },
  AUTHENTICATION: {
    GET: {
      PATH: '/api/authentication/get/:password',
      PARAMS: {
        PASSWORD: ':password',
      },
    },
  },
};

export const constants = {
  BLANK: '',
};

export const messages = {
  texts: {
    contactText: 'Falar com o proprietário',
  },
  formErrors: {
    listing: {
      brand: 'Marca não deve ficar vazio',
      model: 'Modelo não deve ficar vazio',
      year: 'Ano deve ser um número maior que 1900',
      price: 'Preço deve ser um número maior que 0',
      images: 'O anúncio deve ter ao menos uma imagem',
      title: 'O título  do anúncio não deve ser vazio',
    },

  },
  toasts: {
    success: {
      addListing: 'Veículo adicionado com sucesso.',
      editListing: 'Veículo editado com sucesso.',
      deleteListing: 'Veículo deletado com sucesso.',
      tryPassword: 'Logado como administrador.',
      logout: 'Administrador deslogado.',
    },
    error: {
      getAll: 'Erro na obtenção da lista de veículos.',
      addListing: 'Erro na adição de veículo. Tente novamente.',
      editListing: 'Erro na edição de veículo. Tente novamente.',
      deleteListing: 'Falha ao tentar deletar veículo. Atualize a página e tente novamente',
      tryPassword: 'Senha incorreta! Tente novamente.',
    },
  },
  whatsappMessages: {
    aboutPage: {
      baseText: 'Oi, cheguei aqui pelo $LINK, Gostaria de mais informações sobre os veículos.',
      params: {
        link: '$LINK',
      },
    },
    vehiclePage: {
      baseText: 'Oi, me interessei pelo anúncio "$TITLE", acessível em $LINK. Ainda está disponível?',
      params: {
        title: '$TITLE',
        link: '$LINK',
      },
    },
  },
  warnings: {
    mainPage: {
      noListings: 'Nenhum veículo encontrado até o momento. Recarregue a página caso isto persista.',
      noFilteredListings: 'Não foi encontrado nenhum anúncio de veículo coms os filtros aplicados. Resete o filtro clickando em ⟲.',
    },
  },
};
