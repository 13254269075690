/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Paper, Skeleton, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useParams, useNavigate } from 'react-router-dom';
import './style.css';

import VehicleModel from '../../models/VehicleModels';
import { getVehicleByIdProps } from '../../hooks/vehicleListing';

import { useAxios } from '../../hooks/axiosApi';
import formatCurrency from '../../shared/utils';
import theme from '../../shared/theme';
import { constants, messages } from '../../shared/constants';
import { WhatsappButton } from '../../components/WhatsappButton';

/**
 * Vehicle Page Page
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function VehiclePage() {
  const { vehicleid } = useParams();
  const navigate = useNavigate();
  const { data: vehicle } = useAxios(getVehicleByIdProps(vehicleid ? vehicleid.toString() : constants.BLANK));
  const [activeImage, setActiveImage] = useState<string>(constants.BLANK);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (vehicle && vehicle.images && vehicle.images.length) {
      setImages(vehicle.images);
      setActiveImage(vehicle.images[0]);
    }
  }, [vehicle]);

  const handleSetActiveImage = (index: number) => {
    setActiveImage(images[index]);
    setActiveIndex(index);
  };

  const handleSwipeImage = (step: number) => {
    const candidateIndex = activeIndex + step;
    const newIndex = candidateIndex >= 0 && candidateIndex < images.length ? candidateIndex : activeIndex;
    handleSetActiveImage(newIndex);
  };

  return (
    <Grid container
      direction={'row'}
      justifyItems={'center'}
      sx={{ width: '100vw', height: '80vh', padding: 4, mt: 10 }}
      spacing={2}
      mt={2}
    >
      <Grid container alignItems={'center'} spacing={2}>
        <ArrowBackIcon sx={{ height: 60, width: 60, color: '#1976d2', cursor: 'pointer' }} onClick={() => navigate(-1)} />
        <Typography variant='h3' color={theme.palette.primary.dark} fontWeight={800}>{vehicle.listingTitle}</Typography>
      </Grid>
      <Grid item>
        <FullImage
          activeImage={activeImage}
          hideLeft={!activeIndex}
          hideRight={activeIndex == images.length - 1}
          onLeftClick={() => {
            handleSwipeImage(-1);
          }}
          onRightClick={() => {
            handleSwipeImage(1);
          }}
        />
        <Grid container columnGap={2} mt={2} >
          {
            vehicle && vehicle.images ?
              vehicle.images.map((image: string, index: number) => {
                return <img
                  className='image-selector'
                  key={index}
                  src={image}
                  style={
                    {
                      filter: index === activeIndex ? 'brightness(100%)' : 'brightness(25%)',
                    }
                  }
                  onClick={() => handleSetActiveImage(index)}
                />;
              }) :
              <Skeleton variant="rounded" animation="wave" width={'80px'} height={'80px'} />
          }
        </Grid>
      </Grid>
      <Grid item>
        {vehicle && vehicle.model ?
          <VehicleInfo vehicle={vehicle} /> :
          <Skeleton variant="rounded" animation="wave" width={'500px'} height={'150px'} />}
      </Grid>
    </Grid >

  );
};

interface InfoProps {
  vehicle: VehicleModel;
}

const VehicleInfo = (props: InfoProps) => {
  const whatsappMessage = messages.whatsappMessages.vehiclePage.baseText
    .replace(messages.whatsappMessages.vehiclePage.params.link, window.location.href)
    .replace(messages.whatsappMessages.vehiclePage.params.title, props.vehicle.listingTitle);
  const fields = {
    listingTitle: 'Veículo',
    brand: 'Marca',
    model: 'Modelo',
    year: 'Ano',
    price: 'Preço',
  };
  return (
    <Grid container gap={2}>
      <Paper elevation={3} sx={{ background: 'white', borderRadius: 2, p: 2 }}>
        <Grid container direction={'row'} spacing={2}>
          {props.vehicle.brand ? (<LabelAndValue label={fields.brand} value={props.vehicle.brand} />) : null}
          <LabelAndValue label={fields.model} value={props.vehicle.model} />
          {props.vehicle.plate ? (<LabelAndValue label={fields.brand} value={props.vehicle.plate} />) : null}
          <LabelAndValue label={fields.year} value={props.vehicle.year} />
          <LabelAndValue label={fields.price} value={formatCurrency(props.vehicle.price)} highlight />
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <WhatsappButton message={whatsappMessage} text={messages.texts.contactText} />
      </Grid>
      {props.vehicle.description ?
        <Grid item id='description' lg={7} md={12} mb={4}>
          <TextField
            label='Descrição'
            value={props.vehicle.description}
            color='primary'
            multiline
            maxRows={16}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={{ minWidth: 500 }}
          />
        </Grid> :
        <></>}
    </Grid>

  );
};

interface RowProps {
  label: string;
  value: string | number;
  highlight?: boolean;
}

const LabelAndValue = (props: RowProps) => {
  return (
    <Grid item>
      <Grid container
        direction={'column'}
        alignItems={'start'}
        justifyContent={'space-between'}
        sx={{ border: 'solid 1px gray', borderRadius: 2, p: 1 }}
      >
        <Grid item justifySelf={'center'}>
          <Typography variant={'h4'} fontWeight={300}>{props.label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'} fontWeight={800}>{props.value}</Typography>
        </Grid>
      </Grid >
    </Grid>
  );
};

interface FullImageProps {
  activeImage: string;
  onLeftClick: () => void;
  onRightClick: () => void;
  hideLeft?: boolean;
  hideRight?: boolean;
}

const FullImage = (props: FullImageProps) => {
  return (
    <div>
      {
        props.activeImage ?
          <Grid container direction={'row'} alignItems={'center'} >
            <Grid item marginRight={-6} zIndex={props.hideLeft ? -10 : 100} >
              <IconButton onClick={props.onLeftClick}>
                <ArrowBackIosIcon className='image-change-button' />
              </IconButton>
            </Grid>
            <img src={props.activeImage} className={'full-image'} />
            <Grid item marginLeft={-5} zIndex={props.hideRight ? -10 : 100}>
              <IconButton onClick={props.onRightClick}>
                <ArrowForwardIosIcon className='image-change-button' />
              </IconButton>
            </Grid>
          </Grid> :
          <Skeleton variant="rounded" animation="wave" width={'600px'} height={'800px'} />
      }
    </div>
  );
};
