/* eslint-disable semi */

export enum Category {
  car = 'Carro',
  bus = 'Ônibus',
  truck = 'Caminhão',
  motorcycle = 'Moto'
}

export default interface VehicleModel {
  _id: string,
  year: number;
  price: number;
  listingTitle: string;
  model: string;
  brand?: string;
  plate: string;
  description?: string;
  images: string[];
  thumbnail: string;
  category: Category;
  lastUpdate: String;
};
