import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';

const axiosApi = axios.create({
  baseURL: '/',
});

export interface useAxiosProps {
  endpoint: string,
  defaultResponse: any,
  config: AxiosRequestConfig;
}

export const useAxios = (props: useAxiosProps, preventUpdate?: boolean) => {
  const [data, setData] = useState(props.defaultResponse);
  const [error, setError] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!preventUpdate) {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosApi.request({ url: props.endpoint, ...props.config });
      const data = response?.data ? response.data : props.defaultResponse;
      setData(data ? data : props.defaultResponse);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoaded(true);
    }
  };

  return { data, error, loaded };
};

export const fetchAxios = (props: useAxiosProps) => {
  return axiosApi.request({ url: props.endpoint, ...props.config }).then((response) => {
    return { data: response, error: null };
  }).catch((error) => {
    return { data: props.defaultResponse, error };
  });
};

export default axiosApi;
