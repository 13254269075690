
const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    compactDisplay: 'short',
    maximumSignificantDigits: 3,
  });
  return formatter.format(value);
};

export default formatCurrency;
