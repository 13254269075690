import { createContext, useContext } from 'react';
import { FilterSettings } from '../components/Filter';
import VehicleModel from '../models/VehicleModels';
import { constants } from './constants';

export type GlobalContent = {
  objectListings: VehicleModel[];
  setObjectListings: (vehicles: VehicleModel[]) => void;
  filteredObjectListings: VehicleModel[];
  setFilteredObjectListings: (vehicles: VehicleModel[]) => void;
  lastUpdateTimestamp: string;
  setLastUpdateTimestamp: (timestamp: string) => void;
  filterSettings: FilterSettings;
  setFilterSettings: (settings: FilterSettings) => void;
};
export const GlobalContext = createContext<GlobalContent>({
  objectListings: [],
  setObjectListings: ([]) => { },
  filteredObjectListings: [],
  setFilteredObjectListings: ([]) => { },
  lastUpdateTimestamp: constants.BLANK,
  setLastUpdateTimestamp: (ts: string) => [],
  filterSettings: {} as FilterSettings,
  setFilterSettings: () => {
    return;
  },
});

export const useGlobalContext = () => useContext(GlobalContext);
