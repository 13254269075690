import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Button, InputAdornment, Typography, TextField, Select, FormControl, InputLabel, MenuItem, Divider, Skeleton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import toast from '../../shared/toast';
import { constants, messages } from '../../shared/constants';

import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { Category } from '../../models/VehicleModels';
import { fetchAxios, useAxios } from '../../hooks/axiosApi';
import { getVehicleByIdProps, patchVehicleProps, postVehicleProps } from '../../hooks/vehicleListing';
import theme from '../../shared/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../../shared/context';
import { getPassword } from '../../hooks/authentication';

interface Props {
  mode: 'create' | 'edit';
}

export const VehicleCreationPage = (props: Props) => {
  const navigate = useNavigate();
  const { setLastUpdateTimestamp } = React.useContext(GlobalContext);
  const [category, setCategory] = useState<Category>(Category.car);
  const [brand, setBrand] = useState(constants.BLANK);
  const [model, setModel] = useState(constants.BLANK);
  const [year, setYear] = useState<number>(2000);
  const [price, setPrice] = useState<number>(10000);
  const [images, setImages] = useState([]);
  const [listingTitle, setListingTitle] = useState(constants.BLANK);
  const [description, setDescription] = useState(constants.BLANK);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  const { vehicleid } = useParams();
  const vehicleToEditProps = vehicleid ? getVehicleByIdProps(vehicleid) : null;
  const vehicleToEdit = vehicleToEditProps ?
    useAxios(vehicleToEditProps).data :
    {
      _id: vehicleid,
      year: 2000,
      price: 10000,
      listingTitle: constants.BLANK,
      model: constants.BLANK,
      brand: constants.BLANK,
      plate: constants.BLANK,
      images: [],
      category: Category.car,
      description: constants.BLANK,
    };


  useEffect(() => {
    if (props.mode == 'edit' && vehicleToEdit && Object.keys(vehicleToEdit).length) {
      handleSetCurrentVehicle();
    } else if (props.mode == 'edit' && !vehicleToEdit) {
      toast.error(messages.toasts.error.editListing);
    }
  }, [vehicleToEdit, props.mode]);


  useEffect(() => {
    if (category && brand && model && year) {
      const defaultTitle = `${category} ${brand} ${model} ano ${year}`;
      setListingTitle(defaultTitle);
    }
  }, [category, brand, model, year]);

  const getErrorMessage = () => {
    if (brand.length == 0) {
      return messages.formErrors.listing.brand;
    }
    if (model.length == 0) {
      return messages.formErrors.listing.model;
    }
    if (year <= 1900) {
      return messages.formErrors.listing.year;
    }
    if (price <= 0) {
      return messages.formErrors.listing.price;
    }
    if (images.length == 0) {
      return messages.formErrors.listing.images;
    }
    if (listingTitle.length == 0) {
      return messages.formErrors.listing.title;
    }
    return constants.BLANK;
  };

  const handleRegister = async () => {
    const postprops = postVehicleProps({
      category,
      brand,
      model,
      year,
      price,
      images,
      listingTitle,
      description,
    }, getPassword());
    setIsAwaitingResponse(true);
    const { error } = await fetchAxios(postprops);
    setIsAwaitingResponse(false);
    if (error) {
      toast.error(messages.toasts.error.addListing);
      console.error(error);
    } else {
      setLastUpdateTimestamp(constants.BLANK);
      toast.success(messages.toasts.success.addListing);
      navigate('/');
    }
  };

  const handleEditVehicle = async () => {
    const patchEditProps = patchVehicleProps({
      _id: vehicleid ? vehicleid : '0',
      category,
      brand,
      model,
      year,
      price,
      images,
      listingTitle,
      description,
    }, getPassword());
    setIsAwaitingResponse(true);
    const { error } = await fetchAxios(patchEditProps);
    setIsAwaitingResponse(false);
    if (error) {
      toast.error(messages.toasts.error.addListing);
      console.error(error);
    } else {
      setLastUpdateTimestamp(constants.BLANK);
      toast.success(messages.toasts.success.editListing);
    }
  };

  const handleSetCurrentVehicle = () => {
    if (vehicleToEdit) {
      setCategory(vehicleToEdit.category);
      setBrand(vehicleToEdit.brand);
      setModel(vehicleToEdit.model);
      setYear(vehicleToEdit.year);
      setPrice(vehicleToEdit.price);
      const imagesAdjusted: ImageType[] = vehicleToEdit.images.map((image: any) => {
        return { dataURL: image };
      });
      setImages(imagesAdjusted as never[]);
      setListingTitle(vehicleToEdit.listingTitle);
      setDescription(vehicleToEdit.description);
    }
  };

  const areFieldsFilled = useMemo(() => {
    const errorMessage = getErrorMessage();
    return errorMessage.length == 0;
  }, [category, images, brand, model, year, price]);

  return (
    <div>
      {props.mode == 'edit' && !vehicleToEdit.listingTitle ?
        <PageSkeleton /> :
        <Grid container>
          <Grid item mt={2}>
            <Typography variant='h4'> {props.mode == 'create' ? 'Registre um novo veículo' : 'Edite o registro do veículo:'}</Typography>
          </Grid>
          <Grid container direction={'row'} gap={3} mt={2}>
            <Grid item minWidth={'220px'}>
              <FormControl fullWidth>
                <InputLabel>Categoria</InputLabel>
                <Select
                  value={category}
                  label='Categoria'
                  disabled={isAwaitingResponse}
                  onChange={(e) => setCategory(e.target.value as Category)}
                >
                  {Object.values(Category).map((category) => {
                    return (
                      <MenuItem key={category} value={category}>{category}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField label={'Marca'} value={brand} disabled={isAwaitingResponse}
                onChange={(e) => setBrand(e.target.value)} />
            </Grid>
            <Grid item>
              <TextField label={'Modelo'} value={model} disabled={isAwaitingResponse}
                onChange={(e) => setModel(e.target.value)} />

            </Grid>
            <Grid item>
              <TextField type='number' label={'Ano'} value={year} disabled={isAwaitingResponse}
                InputProps={{
                  inputProps: {
                    min: 1940, max: new Date().getFullYear(),
                  },
                }}
                onChange={(e) => setYear(Number(e.target.value))} />
            </Grid>
            <Grid item>
              <TextField type='number' label={'Preço'} value={price} disabled={isAwaitingResponse}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  inputProps: {
                    min: 500, step: 500,
                  },
                }}
                onChange={(e) => setPrice(Number(e.target.value))} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth label={'Titulo do anúncio'} value={listingTitle} disabled={isAwaitingResponse}
                onChange={(e) => setListingTitle(e.target.value)} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth label={'Descrição'} value={description} disabled={isAwaitingResponse}
                multiline minRows={2} maxRows={5} onChange={(e) => setDescription(e.target.value)} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <UploadComponent images={images} setImages={setImages} disabled={isAwaitingResponse} />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {
              !areFieldsFilled ?
                <Typography variant='body1' color={theme.palette.error.dark}>*{getErrorMessage()}</Typography> :
                <></>}
          </Grid>
          <Grid item xs={12} mb={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button variant='outlined' disabled={!areFieldsFilled || isAwaitingResponse} onClick={() => {
              props.mode == 'create' ? handleRegister() : handleEditVehicle();
            }}>
              {props.mode == 'create' ? 'Registrar' : 'Salvar Modificações'}
            </Button>
          </Grid>
        </Grid >
      }
    </div>
  );
};

interface UploadProps {
  disabled?: boolean;
  images: ImageListType;
  setImages: Function;
}

const UploadComponent = (props: UploadProps) => {
  const maxImages = 8;

  useEffect(() => {
  }, [props.images]);

  const onChange = (imageList: ImageListType) => {
    props.setImages(imageList as never[]);
  };

  const onSetPrimaryImage = (index: number) => {
    const image = props.images[index];
    const newImages = [...props.images];
    newImages.splice(index, 1);
    newImages.unshift(image);
    props.setImages(newImages);
  };

  const getErrorMessage = (errors: any) => {
    if (!errors) {
      return constants.BLANK;
    }
    if (errors.maxNumber) {
      return 'Numero de imagens maximo excedido';
    }
    if (errors.acceptType) {
      return 'Tipo de arquivo nao suportado';
    }
    if (errors.maxFileSize) {
      return 'Tamanho maximo de arquivo excedido';
    }
    if (errors.resolution) {
      return 'Resolucao fora do padrao';
    }
    return constants.BLANK;
  };

  return (
    <ImageUploading
      multiple
      value={props.images}
      onChange={onChange}
      maxNumber={maxImages}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        <div>
          <Button
            variant='outlined'
            size={'medium'}
            style={isDragging ? { color: 'red' } : undefined}
            disabled={props.disabled}
            onClick={onImageUpload}
            {...dragProps}
          >
            <PhotoLibraryIcon />
          </Button>
          <Button
            variant='outlined'
            color={'error'}
            size={'medium'}
            sx={{ m: 3 }}
            disabled={props.disabled}
            onClick={onImageRemoveAll}>
            <DeleteOutlineIcon />
            {' Todas'}
          </Button>
          <Grid container direction={'row'} alignItems='end' gap={2}>
            {imageList.map((image, index) => (
              <Grid item key={index} >
                <img src={image.dataURL} alt={constants.BLANK} width={200} height='auto' style={index == 0 ? { border: 'dashed 2px black' } : {}} />
                <div>
                  <Button variant='outlined' color={'warning'} size={'small'} sx={{ fontSize: 10, mr: 2 }}
                    disabled={props.disabled}
                    onClick={() => onSetPrimaryImage(index)}>
                    Tornar Principal
                  </Button>
                  <Button variant='contained' color={'error'} size={'small'} disabled={props.disabled}
                    onClick={() => onImageRemove(index)}>
                    <DeleteOutlineIcon sx={{ width: 20, height: 20 }} />
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
          {
            getErrorMessage(errors) ? <p style={{ color: 'red' }}>{getErrorMessage(errors)}</p> : <></>
          }
        </div>
      )}
    </ImageUploading>
  );
};

const PageSkeleton = () => {
  return (
    <Grid container gap={8}>
      <Skeleton animation="wave" variant='rectangular' width={'90vw'} height={300} sx={{ marginTop: 6 }} />
      <Skeleton animation="wave" variant='rectangular' width={'60vw'} height={150} />
      <Grid item xs={12}>
        <Skeleton variant='rectangular' width={100} height={50} />
      </Grid>
    </Grid>
  );
};
