/* eslint-disable indent */
import { routes } from '../shared/constants';
import axios, { AxiosError } from 'axios';
import { Category } from '../models/VehicleModels';
import { ImageListType } from 'react-images-uploading';

const getQueryParam = (param: string, value: string) => {
  return `?${param}=${value}`;
};

const getVechiclesEndpoint = () => {
  return routes.VEHICLE_LISTING.GET.ALL.PATH;
};

const postVechicleEndpoint = (password: string) => {
  const paramKey = routes.VEHICLE_LISTING.POST.QUERY_PARAMS.PASSWORD;
  return `${routes.VEHICLE_LISTING.POST.ONE.PATH}${getQueryParam(paramKey, password)}`;
};

const patchVechicleEndpoint = (vehicleId: string, password: string) => {
  const paramKey = routes.VEHICLE_LISTING.PATCH.BY_ID.QUERY_PARAMS.PASSWORD;
  return `${routes.VEHICLE_LISTING.PATCH.BY_ID.PATH.
    replace(routes.VEHICLE_LISTING.PATCH.BY_ID.PARAMS.ID, vehicleId)}${getQueryParam(paramKey, password)}`;
};

const deleteVechicleEndpoint = (id: string, password: string) => {
  const paramKey = routes.VEHICLE_LISTING.DELETE.BY_ID.QUERY_PARAMS.PASSWORD;
  return `${routes.VEHICLE_LISTING.DELETE.BY_ID.PATH.
    replace(routes.VEHICLE_LISTING.DELETE.BY_ID.PARAMS.ID, id)}${getQueryParam(paramKey, password)}`;
};

const getVechicleByIdEndpoint = (vehicleId: string) => {
  const idParamKey: string = routes.VEHICLE_LISTING.GET.BY_ID.PARAMS.ID;
  return routes.VEHICLE_LISTING.GET.BY_ID.PATH.replace(idParamKey, vehicleId);
};

export const getVehicleByIdProps = (vehicleId: string) => {
  const endpoint = getVechicleByIdEndpoint(vehicleId);
  const props = { endpoint, defaultResponse: {}, config: { method: 'GET' } };
  return props;
};

export const getAllVehiclesProps = () => {
  const endpoint = getVechiclesEndpoint();
  const props = { endpoint, defaultResponse: [], config: { method: 'GET' } };
  return props;
};

interface VehiclePostData {
  _id?: string,
  category: Category,
  brand: string,
  model: string,
  year: number,
  price: number,
  images: ImageListType,
  listingTitle: string,
  description: string,
}

export const postVehicleProps = (data: VehiclePostData, password: string) => {
  const endpoint = postVechicleEndpoint(password);
  const props = { endpoint, defaultResponse: [], config: { method: 'POST', data } };
  return props;
};

interface VehiclePatchData {
  _id: string,
  category: Category,
  brand: string,
  model: string,
  year: number,
  price: number,
  images: ImageListType,
  listingTitle: string,
  description: string,
}

export const patchVehicleProps = (data: VehiclePatchData, password: string) => {
  const endpoint = patchVechicleEndpoint(data._id, password);
  const props = { endpoint, defaultResponse: [], config: { method: 'PATCH', data } };
  return props;
};

export const deleteVehicleById = async (id: string, password: string) => {
  const endpoint = deleteVechicleEndpoint(id, password);
  try {
    const response = await axios.delete(endpoint);
    return { data: response.data };
  } catch (error) {
    const axiosError = error as AxiosError;
    return { error: axiosError };
  }
};
