import React, { useState, useEffect, useMemo } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, Grid, IconButton, Paper, SxProps, Theme, Typography,
} from '@mui/material';

import {
  DirectionsCarFilledRounded as CarIcon,
  DirectionsBusRounded as BusIcon,
  TwoWheelerRounded as MotorcycleIcon,
  LocalShippingRounded as TruckIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { Category } from '../../models/VehicleModels';
import VehicleModel from '../../models/VehicleModels';

import { useNavigate } from 'react-router-dom';
import theme from '../../shared/theme';
import { deleteVehicleById } from '../../hooks/vehicleListing';
import { GlobalContext } from '../../shared/context';
import { getPassword, isAdmin } from '../../hooks/authentication';
import toast from '../../shared/toast';
import { constants, messages } from '../../shared/constants';

const cardStyle: SxProps<Theme> = {
  width: '300px',
  borderRadius: 2,
  elevation: 4,
  padding: 0,
  margin: 2,
};

interface Props {
  vehicle: VehicleModel;
}
/**
 * VehicleCard component
 * @param {props} props the vehicle to be rendered
 * @return {JSX.Element}
 */
export default function VehicleCard(props: Props) {
  const { setLastUpdateTimestamp } = React.useContext(GlobalContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isUserAuthed = isAdmin();

  const navigate = useNavigate();
  const handleNavigate = () => {
    const vehicleRoute = props.vehicle._id ? `vehicle/${props.vehicle._id}` : null;
    if (vehicleRoute) {
      navigate(vehicleRoute);
    } else {
      alert(`missing id for ${props.vehicle.listingTitle}`);
    }
  };

  const handleDelete = async () => {
    const { error } = await deleteVehicleById(props.vehicle._id, getPassword());
    if (error) {
      console.log(error);
      toast.error(messages.toasts.error.deleteListing);
    } else {
      setLastUpdateTimestamp(constants.BLANK);
      toast.success(messages.toasts.success.deleteListing);
      console.log(`Successfully deleted vehicle with id: ${props.vehicle._id}`);
    }
  };

  return (
    <Grid container alignContent={'space-between'} sx={cardStyle} component={Paper} rowGap={2}>
      <Grid item id={'image'} xs={12}>
        <Paper
          elevation={2}
          style={{
            padding: 0,
          }}
        >
          {/* TODO: Replace single image for carousel */}
          <img src={props.vehicle.thumbnail}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', maxHeight: '270px', borderRadius: 2 }}
            onClick={handleNavigate}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          id={'listingInfo'}
          direction={'row'}
          justifyContent={'space-around'}
        >
          <Grid item xs={12} pl={1} pr={1} sx={{ height: '40px' }} >
            <ListingName name={props.vehicle.listingTitle} category={props.vehicle.category} />
          </Grid>
          <Grid item xs={12} p={1}>
            <Divider />
          </Grid>
          <Grid item xs={12} pl={1} pr={1}>
            <InfoListing price={props.vehicle.price} year={props.vehicle.year} />
          </Grid>
          {isUserAuthed ?
            <Grid container
              pl={1}
              pr={1}
              justifyContent={'flex-end'}>
              <IconButton
                sx={{ color: theme.palette.primary.main }}
                onClick={() => {
                  const editRoute = `/vehicle/edit/${props.vehicle._id}`;
                  navigate(editRoute);
                }}>
                <EditIcon />
              </IconButton>
              <IconButton
                sx={{ color: theme.palette.error.main }}
                onClick={() => {
                  setIsDeleteDialogOpen(true);
                }}>
                <DeleteIcon />
              </IconButton>
            </Grid> :
            <></>
          }
        </Grid>
      </Grid>
      <DeleteDialog
        listingTitle={props.vehicle.listingTitle}
        open={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        onDelete={handleDelete}
      />
    </Grid >
  );
}

interface ListingProps {
  name: string;
  category: Category;
}

const ListingName = (props: ListingProps) => {
  return (
    <Grid container justifyContent={'space-around'} alignContent={'center'}>
      <Grid item xs={10}>
        <Typography fontSize={{ xs: '1.4ch', md: '2.0ch' }}>{props.name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <CategoryIcon category={props.category} />
      </Grid>
    </Grid >
  );
};

interface CategoryProps {
  category: Category;
}

const CategoryIcon = (props: CategoryProps) => {
  if (props.category === Category.bus) {
    return <BusIcon />;
  }
  if (props.category === Category.car) {
    return <CarIcon />;
  }
  if (props.category === Category.motorcycle) {
    return <MotorcycleIcon />;
  }
  if (props.category === Category.truck) {
    return <TruckIcon />;
  }
  return <></>;
};

interface infoListingProps {
  price: number;
  year: number;
}

const InfoListing = (props: infoListingProps) => {
  const formattedPrice = () => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
    });
    return formatter.format(props.price);
  };
  return (
    <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        <Typography fontSize={'1.4ch'} fontWeight={800}>{formattedPrice()}</Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={'1.4ch'} color={'#46505A'} fontWeight={800}>{props.year}</Typography>
      </Grid>
    </Grid>
  );
};

interface DeleteDialogProps {
  listingTitle: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onDelete: () => void;
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const title = 'Deletar o anúncio?';
  const subtitle = `Tem certeza que deseja deletar o anúncio '${props.listingTitle}'`;

  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={handleClose}>Cancelar</Button>
        <Button variant={'outlined'} color={'error'} onClick={() => {
          handleClose();
          props.onDelete();
        }}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
